<template>
  <div class="content_box">
    <loginHeader :title="$route.meta.title"></loginHeader>

    <div class="bind_box">
      <div class="input_box">
        
        <input type="text" v-model="mobile" placeholder="输入手机号码" />
      </div>
      <div class="input_box">
        <input type="text" v-model="code" placeholder="输入验证码" />
        <span class="after" @click="sendCode" v-if="codeSwitch"
          >获取验证码</span
        >
        <span v-else class="after" >{{ times }}s</span>
      </div>
      <div class="input_box">
       
        <input type="password" v-model="password" placeholder="6~16位数字或字母，区分大小写" />
      </div>
      <div class="input_box">
       
        <input type="password" v-model="rePassword" placeholder="确认密码" />
      </div>
     
    </div>

    <div>
      <span class="btn" @click="toRegister">注册</span>

    
    </div>
  </div>
</template>
<script>
import {Toast} from "vant";
import "../../assets/css/loginRegister.scss";
import {login, loginByTelMsg} from "../../utils/api";
import {mapMutations} from "vuex";
export default {
  data() {
    return {
      code: null,
      mobile: null,
      password: null,
      rePassword: null,
      codeSwitch: true, //验证码状态
      times: 60, //秒数
      registerCode: null,
    };
  },
  mounted() {
    this.registerCode = this.$route.query.registerCode;
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    sendCode() {
      console.log("in");
      let that = this;
      let myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if(!myreg.test(this.mobile)){
        Toast("请输入正确的手机号");
      }else if (that.mobile && myreg.test(this.mobile)) {
        login({
          tel: that.mobile,
        })
          .then((res) => {
            that.mobileAfter=true;
            that.codeSwitch = false;
            let timer = setInterval(() => {
              that.times--;
              if (that.times == 0) {
                that.codeSwitch = true;
                that.times = 60;
                clearInterval(timer);
              }
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Toast("手机号码不能为空");
      }
    },
    toRegister() {
      if(!this.mobile){
        Toast("手机号码不能为空");
        return false;
      }
      if(!this.code){
        Toast("验证码不能为空");
        return false;
      }
      if(!this.password){
        Toast("密码不能为空");
        return false;
      }
      let that = this;
      if (this.password == this.rePassword) {
        loginByTelMsg({
          tel: this.mobile,
          msgCode: this.code,
          password: this.password,
          flag: 1,
        }).then((res) => {
          that.changeLogin({Authorization: res.data.token});
          Toast.success("注册成功，正在登录...");
          setTimeout(() => {
            Toast.clear();
            that.$router.replace(this.$route.query.redirect || "user");
          }, 500);
          // setTimeout(() => {
          //   Toast.clear();
          //   that.$router.replace("/login");
          // }, 1000);
        });
      } else {
        Toast.success("两次密码不一致");
      }
    },
   
    toagreement() {
      this.$router.push("/argument");
    },
  },
};
</script>
